<template>
  <div class="content-box">
    <main class="content-main">
      <div class="title">
        <span>BTC Report</span>
      </div>
      <div class="content">
        <div class="picker-box">
          <report-picker :data="dates" @change-year="handleChangeYear" @change-month="handleChangeMonth"/>
        </div>
        <div class="report-card-box">
          <div v-for="item in list" :key="item.month">
            <report-tag :title="item.month"/>
            <div class="card-box">
              <report-card v-for="(citem, cindex) in item.data" :key="citem.BulletinID" :data="citem" :is-en-expire="isReportEnExpire" :class="{ ml: cindex % 4 !== 0 }" class="report-card" />
            </div>
          </div>
          <div v-if="list.length === 0" class="no-data">
            No Data
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import ReportTag from './components/report-tag'
import ReportCard from './components/report-card-small'
import ReportPicker from './components/datepicker'
import commonmix from '@/utils/commonmix'

export default {
  mixins: [commonmix],
  name: 'BTCInfoMore',
  components: {
    ReportTag,
    ReportCard,
    ReportPicker
  },
  data () {
    return {
      dates: {},
      list: [],
      listQuery: {
        Year: '2021',
        Month: '',
        Lang: 1
      },
      isReportEnExpire: false
    }
  },
  mounted () {
    this.getReportDate()
    this.isReportEnExpire = this.getUserProfile(this.$t('message._beefreports_en'))
  },
  methods: {
    getReportDate () {
      this.$store.dispatch('SetLoadingStatus', true)
      this.$store.dispatch('GetBeefInfoReportDate', {
        Lang: 1
      }).then(() => {
        this.dates = this.$store.getters.newsRow
        console.log(this.dates)
        this.$store.dispatch('SetLoadingStatus', false)
        this.getReportByDate()
      }).catch((err) => {
        this.$store.dispatch('SetLoadingStatus', false)
        console.log(err)
      })
    },
    getReportByDate () {
      this.$store.dispatch('SetLoadingStatus', true)
      this.$store.dispatch('GetBeefInfoReportByDate', this.listQuery).then(() => {
        this.list = this.$store.getters.newsRows
        this.$store.dispatch('SetLoadingStatus', false)
      }).catch((err) => {
        this.$store.dispatch('SetLoadingStatus', false)
        console.log(err)
      })
    },
    handleChangeYear (year) {
      this.listQuery.Year = year
      this.getReportByDate()
    },
    handleChangeMonth (month) {
      this.listQuery.Month = month
      this.getReportByDate()
    }
  }
}
</script>

<style lang="scss" scoped>
$padding-num: 65px;
$bottom-color: #f6f6f6;

.content-box {
  display: flex;
  justify-content: center;
  background-color: #f5f5f5;
}
.content-main {
  width: 1248px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  min-height: 445px;
}
.content-main > .title {
  height: 60px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  font-size: 25px;
  border-radius: 8px;
  background-color: #ffffff;
}
.content-main > .content {
  background-color: #ffffff;
  margin: 20px 0;
  border-radius: 20px;
}
.content-main > .content > .picker-box {
  height: 60px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid $bottom-color;
  padding: 0 $padding-num;
}
.content-main > .content > .report-card-box {
  padding: 0 $padding-num;
}

.report-card-box > div {
  padding: 20px 0;
}

.report-card-box > div:not(:last-child) {
  border-bottom: 1px solid $bottom-color;
}

.card-box {
  display: flex;
  flex-flow: wrap;
  justify-content: flex-start;
}

.report-card {
  width: 265px;
  height: 80px;
  margin-top: 10px;
}

.ml {
  margin-left: 12px;
}

.no-data {
  height: 260px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
