<template>
  <div class="report-tag-box">
    <div class="dot" />
    <img src="@/assets/image/btcinfo/cow.png" />
    <span>{{ getMonth(title) }}</span>
  </div>
</template>

<script>
export default {
  name: 'BTCInfoReportCard',
  props: {
    title: {
      type: Number,
      default: 0
    }
  },
  methods: {
    getMonth (month) {
      switch (month) {
        case 1:
          return 'January'
        case 2:
          return 'February'
        case 3:
          return 'March'
        case 4:
          return 'April'
        case 5:
          return 'May'
        case 6:
          return 'June'
        case 7:
          return 'July'
        case 8:
          return 'August'
        case 9:
          return 'September'
        case 10:
          return 'October'
        case 11:
          return 'November'
        case 12:
          return 'December'
        default:
          break
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$box_size: 40px;
$img_size: 30px;

.report-tag-box {
  height: $box_size;
  display: inline-flex;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0 0 5px 2px #e0e0e0;
}
.report-tag-box > .dot {
  min-width: 12px;
  min-height: 12px;
  border-radius: 6px;
  background-color: #6bb37a;
  margin: 0 10px;
  transform: scale(.8);
}
.report-tag-box > img {
  width: $img_size;
  height: $img_size;
}
.report-tag-box > span {
  color: #e22229;
  font-weight: bold;
  margin: 0 20px;
}
</style>
